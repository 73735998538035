var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-link',{staticClass:"event-card-widget d-flex flex-column align-items-stretch",attrs:{"to":!_vm.urlOpenNewWindow ? _vm.handleBaseUrl : null},on:{"click":function($event){return _vm.onCardClick(_vm.handleBaseUrl)}}},[_c('div',{staticClass:"d-flex header justify-content-center position-relative"},[_c('div',{staticClass:"banner position-absolute overflow-y-hidden",class:[
        !_vm.bannerFileResource ? 'empty' : '',
        _vm.state === _vm.statesEnum.ARCHIVED ? 'archived' : ''
      ],style:(_vm.bannerFileResource
        ? ("background-image: url(" + (_vm.FileResourceHelper.getFullPath(_vm.bannerFileResource, 'w256')) + ")")
        : '')},[_c('div',{staticClass:"d-flex justify-content-between"},[(_vm.state === _vm.statesEnum.LIVE && _vm.displayLiveBadge)?_c('pill-widget',{staticClass:"status-badge",attrs:{"label":("" + (_vm.$t('actions.live'))),"with-borders":true,"size":"xs","variant":"red"}}):_vm._e(),(_vm.state === _vm.statesEnum.SOON && _vm.displayLiveBadge)?_c('pill-widget',{staticClass:"status-badge",attrs:{"label":("" + (_vm.$t('actions.soon'))),"with-borders":true,"size":"xs","variant":"red"}}):_vm._e(),(_vm.state === _vm.statesEnum.ARCHIVED && _vm.displayLiveBadge)?_c('pill-widget',{staticClass:"status-badge",attrs:{"label":("" + (_vm.$t('actions.archived'))),"with-borders":true,"size":"xs","variant":"dark-gray"}}):_vm._e(),_c('button-icon-component',{staticClass:"w-fit ml-auto mr-2 mt-2",attrs:{"id":("bookmark-" + _vm.uid),"variant":_vm.bookmarked ? 'alt-2' : 'tertiary',"boundary":"document","placement":"top","shape":"round","size":"md"},on:{"on-click":_vm.toggleBookmark},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":_vm.bookmarked
                ? 'fa-solid fa-bookmark'
                : 'fa-regular fa-bookmark',"icon-classes":!_vm.bookmarked ? 'text-neutral-n-8-dark-ink' : '',"container-classes":"icon-24"}})]},proxy:true}])})],1)]),_c('avatar-solo-widget',{attrs:{"src":_vm.FileResourceHelper.getFullPath(_vm.logoFileResource, 'w96'),"with-borders":true,"size":"96"}})],1),_c('div',{staticClass:"company-info"},[_c('h6',{staticClass:"text-neutral-n-8-dark-ink title mb-2 mt-3"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('p',{staticClass:"text-neutral-n-6-label mb-0 label-2"},[_vm._v(" "+_vm._s(_vm.dates)+" ")])]),(_vm.authUser)?_c('b-tooltip',{attrs:{"target":("bookmark-" + _vm.uid),"title":_vm.bookmarked
      ? _vm.$t('actions.tooltip.bookmark.remove')
      : _vm.$t('actions.tooltip.bookmark.add'),"boundary":"document","custom-class":"tooltip-style","placement":"top","triggers":"hover"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }