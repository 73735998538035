import { render, staticRenderFns } from "./EventCardWidget.vue?vue&type=template&id=1e107c96&scoped=true&"
import script from "./EventCardWidget.vue?vue&type=script&lang=ts&"
export * from "./EventCardWidget.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/EventCardWidget.scss?vue&type=style&index=0&id=1e107c96&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e107c96",
  null
  
)

export default component.exports