
































































































import { Component, Prop } from 'vue-property-decorator';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  fromUnixTime,
  getUnixTime,
  isAfter,
  isBefore,
  isSameDay,
  isWithinInterval,
  subDays,
} from 'date-fns';
import { Getter, namespace, State } from 'vuex-class';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Venue from '@/models/graphql/Venue';
import FileResource from '@/models/graphql/FileResource';
import PillWidget from '@/components/pill/PillWidget.vue';
import { deepGet } from '@/utils/ObjectHelpers';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import Community from '@/models/graphql/Community';
import EventStateEnum from '@/utils/enums/EventStateEnum';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';

const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');

@Component({
  components: {
    AvatarSoloWidget, FontAwesomeComponent, ButtonIconComponent, PillWidget,
  },
  inheritAttrs: false,
})
export default class EventCardWidget extends BaseCardWidget {
  @Getter
  authUser!: CommunityUser;

  @Getter
  community!: Community;

  @communityUserBookmarkStore.Action
  bookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  unBookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @Prop({ default: null })
  readonly cardRoute!: string;

  @Prop({ required: true, default: -1 })
  readonly id!: number;

  @Prop({ required: false, default: null })
  readonly _isBookmarked!: string | null;

  @Prop({ default: 'DependencyWidgetStore' })
  readonly context!: string;

  @Prop({ required: false, default: true })
  readonly displayLiveBadge!: boolean;

  @Prop({ required: false, default: false })
  readonly archived!: boolean;

  @Prop({ required: true, default: '' })
  private readonly uid!: string;

  @Prop({ required: true, default: '' })
  private readonly name!: string;

  @Prop({ required: true, default: '' })
  private readonly code!: string;

  @Prop({ required: false, default: null })
  private readonly startTimestamp!: number;

  @Prop({ required: false, default: null })
  private readonly endTimestamp!: number;

  @Prop({ required: false, default: null })
  private readonly venue!: Venue;

  @Prop({ required: false, default: null })
  private readonly bannerFileResource!: FileResource;

  @Prop({ required: false, default: null })
  private readonly logoFileResource!: FileResource;

  private statesEnum = EventStateEnum;

  @State
  private dateLocale!: Locale;

  // eslint-disable-next-line no-underscore-dangle
  private bookmarked = this._isBookmarked;

  private localStartTimestamp = this.startTimestamp
    ? getUnixTime(DateTimeHelper.toUTC(fromUnixTime(this.startTimestamp)))
    : null;

  private localEndTimestamp = this.endTimestamp
    ? getUnixTime(DateTimeHelper.toUTC(fromUnixTime(this.endTimestamp)))
    : null;

  private FileResourceHelper = FileResourceHelper;

  private today = new Date();

  private empty = !this.bannerFileResource;

  private get handleBaseUrl(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  private get dates(): string {
    if (this.localStartTimestamp && this.localEndTimestamp) {
      const start = DateTimeHelper.formatFromUnixTime(
        this.localStartTimestamp,
        `${this.$t('app.date.defaultDateFormat')}`,
        this.dateLocale,
      );
      const end = DateTimeHelper.formatFromUnixTime(
        this.localEndTimestamp,
        `${this.$t('app.date.defaultDateFormat')}`,
        this.dateLocale,
      );
      return `${start}${this.$t('app.date.dateTimeSeparator')}${end}`;
    }
    return '';
  }

  private get state(): string {
    if (this.archived) {
      return this.statesEnum.ARCHIVED;
    }
    if (
      this.localStartTimestamp
      && this.localEndTimestamp
      && isWithinInterval(this.today, {
        start: fromUnixTime(this.localStartTimestamp),
        end: fromUnixTime(this.localEndTimestamp),
      })
    ) {
      return this.statesEnum.LIVE;
    }
    if (this.localStartTimestamp) {
      const dayBefore = subDays(fromUnixTime(this.localStartTimestamp), 1);
      if (isSameDay(dayBefore, this.today) || isAfter(dayBefore, this.today)) {
        return this.statesEnum.SOON;
      }
    }
    if (this.localEndTimestamp && isBefore(fromUnixTime(this.localEndTimestamp), this.today)) {
      return this.statesEnum.ARCHIVED;
    }
    return '';
  }

  private toggleBookmark(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.bookmarked) {
      const temp = this.bookmarked;
      this.bookmarked = null;
      this.unBookmark({ uid: temp })
        .catch(() => {
          this.bookmarked = temp;
        });
    } else {
      this.bookmarked = 'bookmarked';
      this.bookmark({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: EntityType.SUB_EDITION,
      }).then((response) => {
        this.bookmarked = response?.uid || '';
        if (this.bookmarked) {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            EntityType.SUB_EDITION,
            StatLoggerActions.ADD,
            '',
            this.id,
            this.uid,
            StatLoggerCategories.BOOKMARK,
            this.$i18n.locale,
          );
        }
      }).catch(() => {
        this.bookmarked = null;
      });
    }
  }
}
